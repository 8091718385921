/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import * as rrweb from 'rrweb';
import useRecord from '@/store/record';
export default {
  __name: 'App',

  setup(__props) {
    const store = useRecord();
    const recordInstance = ref();
    watchEffect(() => {
      if (store.getRecordMutex) {
        nextTick(() => {
          if (recordInstance.value) return;
          recordInstance.value = rrweb.record({
            // 12秒后停止页面的录制，如果想一直录得话可以去掉。
            emit(event) {
              // setTimeout(() => {
              //   stopFn();
              // }, 12000);
              console.log('event ==>', event); // 用任意方式存  储 event

              store.updateEvents({
                event
              }); // store.commit("updateEvents", { event });
            }

          });
          ElMessage({
            message: "开启视频录制",
            type: "success"
          });
        });
      } else {
        recordInstance.value();
        recordInstance.value = null;
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view)]);
    };
  }

};