export default {
  actions: {
    updateEvents({ event }) {
      this.events.push(event || {})
    },
    updateRecordStatus() {
      const status = this.recordMutex
      this.recordMutex = !status
    },
    clearEvents() {
      this.events.splice(0, this.events.length)
    }
  }
};
