export default {
  state: () => ({
    events: [],
    recordMutex: true
  }),
  getters: {
    getEvents: (state) => state.events,
    getRecordMutex: (state) => state.recordMutex
  }
};
